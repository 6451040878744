import GraphTabs from 'graph-tabs';
const tabsTeam = new GraphTabs('team');

const tabsButtons = document.querySelectorAll('.tabs__button');

// Проходимся по всем кнопкам
tabsButtons.forEach(btn => {
  // вешаем на каждую кнопку обработчик события клик
  btn.addEventListener('click', () => {
    // Получаем предыдущую активную кнопку
    const prevActiveItem = document.querySelector('.tabs__item._active');
    // Получаем предыдущую активную вкладку
    const prevActiveButton = document.querySelector('.tabs__button._active');

    // Проверяем есть или нет предыдущая активная кнопка
    if (prevActiveButton) {
      //Удаляем класс _active у предыдущей кнопки если она есть
      prevActiveButton.classList.remove('_active');
    }

    // Проверяем есть или нет предыдущая активная вкладка
    if (prevActiveItem) {
      // Удаляем класс _active у предыдущей вкладки если она есть
      prevActiveItem.classList.remove('_active');
    }
    // получаем id новой активной вкладки, который мы перем из атрибута data-tab у кнопки
    const nextActiveItemId = `#${btn.getAttribute('data-tab')}`;
    // получаем новую активную вкладку по id
    const nextActiveItem = document.querySelector(nextActiveItemId);

    // добавляем класс _active кнопке на которую нажали
    btn.classList.add('_active');
    // добавляем класс _active новой выбранной вкладке
    nextActiveItem.classList.add('_active');
  });
});

const nextBtns = document?.querySelectorAll('.team__card-navigation--next');
const prevBtns = document?.querySelectorAll('.team__card-navigation--prev');
const tabBtns = document?.querySelectorAll('.tabs__nav-btn');
const tabPanels = document?.querySelectorAll('.tabs__panel')

for (const item of tabBtns) {
  item.addEventListener('click', () => {
    document.querySelector('.tabs__nav-btn--active').classList.remove('tabs__nav-btn--active');
    document.querySelector('.tabs__panel--active').classList.remove('tabs__panel--active');
    item.classList.add('tabs__nav-btn--active');
    document.querySelector(`[aria-labelledby=${item.id}]`).classList.add('tabs__panel--active');
  })
}

for (const item of nextBtns) {
  item.addEventListener('click', () => {
    let [btnActive, panelActive] = [...getActive()]
    let [nextBtn, nextPanel] = getAtr(btnActive, panelActive, true);
    if (nextBtn !== undefined) {
      btnActive.classList.remove('tabs__nav-btn--active');
      panelActive.classList.remove('tabs__panel--active');
      nextBtn.classList.add('tabs__nav-btn--active');
      nextPanel.classList.add('tabs__panel--active');
    }
    return false
  })
}

for (const item of prevBtns) {
  item.addEventListener('click', () => {
    let [btnActive, panelActive] = [...getActive()];
    let [nextBtn, nextPanel] = [...getAtr(btnActive, panelActive, false)];
    if (nextBtn !== undefined) {
      btnActive.classList.remove('tabs__nav-btn--active');
      panelActive.classList.remove('tabs__panel--active');
      nextBtn.classList.add('tabs__nav-btn--active');
      nextPanel.classList.add('tabs__panel--active');
    }
  })
}

function getActive() {
  const activeBtnTab = document.querySelector('.team .tabs__nav-btn--active');
  const activePanetTab = document.querySelector('.team .tabs__panel--active');
  return [activeBtnTab, activePanetTab];
};

function getAtr(btn, panel, dir) {
  let btnId = btn.id.slice(-1);
  let panelAtr = panel.getAttribute('aria-labelledby').slice(-1);
  let nextBtn
  let nextPanel

  if (dir && btnId < nextBtns.length) {
    nextBtn = Number(btnId) + 1;
    nextPanel = Number(panelAtr) + 1;
    nextBtn = document.getElementById(`${btn.id.slice(0, -1) + nextBtn.toString()}`);
    nextPanel = document.querySelector(`[aria-labelledby='${panel.getAttribute('aria-labelledby').slice(0, -1) + nextPanel.toString()}']`);
  } if (!dir && btnId > 1) {
    nextBtn = Number(btnId) - 1;
    nextPanel = Number(panelAtr) - 1;
    nextBtn = document.getElementById(`${btn.id.slice(0, -1) + nextBtn.toString()}`);
    nextPanel = document.querySelector(`[aria-labelledby='${panel.getAttribute('aria-labelledby').slice(0, -1) + nextPanel.toString()}']`);
  }
  return [nextBtn, nextPanel];
}


