// import Swiper from 'swiper';
// import { Grid, Navigation } from 'swiper/modules';
// Swiper.use([Navigation, Grid]);
// const swiper = new Swiper('.team__swiper', {

//   // navigation: {
//   //   nextEl: '.team__card-navigation--next',
//   //   prevEl: '.team__card-navigation--prev',
//   // },
//   breakpoints: {
//     768: {
//       slidesPerView: "3",
//       spaceBetween: 10,
//       grid: {
//         rows: '2',
//         fill: 'row'
//       },
//     },
//     450: {
//       slidesPerView: "2",
//       spaceBetween: 10,
//       grid: {
//         rows: '2',
//         fill: 'row'
//       },
//     },
//     320: {
//       slidesPerView: "1",
//       spaceBetween: 10,
//       grid: {
//         rows: '1',
//         fill: 'row'
//       },
//     },

//   }
// });
