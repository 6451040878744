const mainMap = document?.getElementById('main-map');

const mapBtns = document?.querySelectorAll('.location__card-btn');

for (const item of mapBtns) {
  item.addEventListener('click', () => {
    document.querySelector('.location__card.active').classList.remove('active');
    item.parentElement.classList.add('active');
  })
}

ymaps.ready(function () {
  const map = new ymaps.Map('main-map', {
    center: [57.129495, 65.488868], // Центр карты по умолчанию
    zoom: 15,
    controls: []
  });
   // Тут прописать геопозицию салонов партнеров
  const oneShot = new ymaps.Placemark([57.129494, 65.498862], {}, {
    iconLayout: 'default#image',
    iconImageHref: '../img/logo.png',
    iconImageSize: [50, 50]

  });
  const tattooFeel = new ymaps.Placemark([57.139494, 65.498862], {}, {
    iconLayout: 'default#image',
    iconImageHref: '../img/logo.png',
    iconImageSize: [50, 50]

  });
  const justTatto = new ymaps.Placemark([57.149494, 65.498862], {}, {
    iconLayout: 'default#image',
    iconImageHref: '../img/logo.png',
    iconImageSize: [50, 50]

  });
  map.geoObjects.add(oneShot);
  map.geoObjects.add(tattooFeel);
  map.geoObjects.add(justTatto);

  // Тут прописать центр карт для партнеров
  document.getElementById('one-shot').addEventListener('click', () => {
    map.setCenter([57.126495, 65.498900]);
  })
  document.getElementById('tattoo-feel').addEventListener('click', () => {
    map.setCenter([57.125495, 65.498900]);
  })
  document.getElementById('just-tattoo').addEventListener('click', () => {
    map.setCenter([57.125795, 65.498900]);
  })
});


